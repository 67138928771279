
.breadcrumbs {
  &__list {
    display: table;
    table-layout: auto;
  }
  &__item {
    display: table-cell;
    vertical-align: middle;
  }
}
.menu {
  display: table;
  width: auto;
  table-layout: auto;

  &--left { margin-left: 0; margin-right: auto; }
  &--center { margin-left: auto; margin-right: auto; }
  &--right { margin-left: auto; margin-right: 0; }

  &__item {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }
}
.article-list {
  &__item-inner {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  &__media,
  &__content {
    display: table-cell;
    vertical-align: top;
  }
}
.pager {
  display: table;
  width: 100%;
  table-layout: fixed;

  &__item {
    display: table-cell;
    width: 50%;
    &--next { padding-right: 2%; }
    &--prev { padding-left: 2%; }
  }
}
.pagination {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.page-numbers {
  display: table-cell;
  vertical-align: middle;
}


.hero__content,
.intro__inner,
.recommend,
.recommend__content,
.content__row,
.footer {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.footer__nav,
.footer__company-info {
  display: table;
  table-layout: fixed;
}
.footer__nav { margin: 0 0 0 auto; }

.intro__img-box,
.intro__txt-box,
.recommend__item,
.footer__company-info,
.footer__logo,
.footer__address,
.footer__nav-item {
  display: table-cell;
  vertical-align: middle;
}
.content__row .main,
.content__row .side {
  display: table-cell;
  vertical-align: top;
}
.content__row .side { width: 360px; }

.hero__content {
  text-align: center;
  padding-top: 128px;
}
.recommend__content { padding: 78px 0; }
.recommend__heading { margin: 0 auto; }
.article-list__date { width: 130px; }
